<template>
  <v-container>
    <v-row class="text-left">

      <!-- English Correction Section -->
      <v-col class="mb-4">

        <v-form>
          <v-radio-group v-model="selectedMode" row>
            <v-radio label="中学生向け" value="junior"></v-radio>
            <v-radio label="高校生向け" value="senior"></v-radio>
          </v-radio-group>

          <!-- Sender Name Input -->
          <v-text-field
            v-model="senderName"
            label="送信者の名前を入力してください"
            outlined
          ></v-text-field>

          <v-textarea
            v-model="message"
            label="ここに英文を入力してください"
            placeholder="add multiple lines"
            rows="5"
            auto-grow
            outlined
          ></v-textarea>

          <v-btn @click="correctEnglish" color="primary">
            添削する
          </v-btn>
          
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="ml-3"
          ></v-progress-circular>

          <div v-if="correctedMessage">
            <h3 class="headline font-weight-bold mb-3">
              添削結果:
            </h3>
            <v-textarea
              v-model="correctedMessage"
              label="添削された英文"
              rows="20"
              readonly
              outlined
            ></v-textarea>
          </div>
        </v-form>
      </v-col>

      <!-- Other Sections ... -->
      <!-- ... -->
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      selectedMode: 'junior',
      message: '',
      senderName: '', // Add sender's name property
      correctedMessage: '',
      loading: false,
      // ... other data properties
    }),

    methods: {
      async correctEnglish() {

        // Check if both sender's name and message are entered
        if (!this.senderName || !this.message) {
          alert('名前と英文を入力してください');
          return;
        }

        this.loading = true;
        const apiURL = 'https://english.api.takoyaki3.com/correct'; // Replace with your API endpoint

        try {
          const response = await fetch(apiURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              mode: this.selectedMode,
              name: this.senderName, // Send sender's name to the API
              text: this.message,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            this.correctedMessage = data.correctedText; // Assume the corrected text is returned in this property
          } else {
            console.error('Error correcting text:', response.statusText);
            this.correctedMessage = '添削に失敗しました。もう一度お試しください。';
          }
        } catch (error) {
          console.error('Error sending request:', error);
          this.correctedMessage = '添削に失敗しました。もう一度お試しください。';
        }

        this.loading = false;
      },
      // ... other methods
    },
  }
</script>
